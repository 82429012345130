import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useAsyncCall } from '../../hooks/useAsyncCall';
import { apiService } from '../../services/api-service';
import { type User } from '../../types';
import { isGuest, isOrgMember } from '../../types/user';
import { err2s } from '../../utils/common';
import { Loading } from '../Loading';
import { useUser, useUserContext } from '../UserContext';
import { ItemContainer } from './ItemContainer';

interface AccountItemProps {
  user: User;
}

export const Account = (): JSX.Element => {
  const user = useUser();
  if (!user.id) return <></>;

  return (
    <div className='w-full flex flex-col gap-7.5'>
      <Email user={user} />
      <FirstName user={user} />
      <LastName user={user} />
      <Username user={user} />
      <Password />
    </div>
  );
};

const Email = ({ user }: AccountItemProps): JSX.Element | null => {
  if (isGuest(user) || !user.email) return null;
  return <ItemContainer title='Email' value={user.email} editable={false} />;
};

const FirstName = ({ user }: AccountItemProps): JSX.Element | null => {
  const { updateUser } = useUserContext();

  const onSave = useCallback(
    async (value: string) => {
      if (!user.organizer) return;

      const organizer = (
        await apiService.organization.updateOrganizer(
          user.organizer.orgId,
          user.organizer.uid,
          { firstName: value }
        )
      ).data.organizer;
      updateUser({ organizer });
    },
    [updateUser, user.organizer]
  );

  if (!user.organizer) return null;

  return (
    <ItemContainer
      title='First Name'
      value={user.organizer.firstName}
      editable
      onSave={onSave}
      minLength={1}
      maxLength={50}
    />
  );
};

const LastName = ({ user }: AccountItemProps): JSX.Element | null => {
  const { updateUser } = useUserContext();

  const onSave = useCallback(
    async (value: string) => {
      if (!user.organizer) return;

      const organizer = (
        await apiService.organization.updateOrganizer(
          user.organizer.orgId,
          user.organizer.uid,
          { lastName: value }
        )
      ).data.organizer;
      updateUser({ organizer });
    },
    [updateUser, user.organizer]
  );

  if (!user.organizer) return null;

  return (
    <ItemContainer
      title='Last Name'
      value={user.organizer.lastName}
      editable
      onSave={onSave}
      minLength={1}
      maxLength={50}
    />
  );
};

const Username = ({ user }: AccountItemProps): JSX.Element => {
  const { updateUser } = useUserContext();

  const onSave = useCallback(
    async (value: string) => {
      await apiService.user.updateUsername(value);
      updateUser({ username: value });
    },
    [updateUser]
  );

  return (
    <ItemContainer
      title='Username'
      value={user.username}
      editable
      onSave={onSave}
      minLength={3}
      maxLength={25}
    />
  );
};

const Password = (): JSX.Element | null => {
  const user = useUser();
  const [editing, setEditing] = useState(false);

  if (isOrgMember(user) || isGuest(user)) return null;

  return (
    <div className='mb-7.5 text-white'>
      {editing ? (
        <PasswordEditor setEditing={setEditing} />
      ) : (
        <PasswordView setEditing={setEditing} />
      )}
    </div>
  );
};

const PasswordView = ({
  setEditing,
}: {
  setEditing: (value: boolean) => void;
}): JSX.Element => {
  return (
    <div>
      <h2 className='font-bold text-base mb-2.5'>Password</h2>
      <div className='w-full text-sms flex flex-row'>
        <div className='w-75'>********</div>

        <div>
          <button
            type='button'
            onClick={() => setEditing(true)}
            className='btn text-primary'
          >
            Change Password
          </button>
        </div>
      </div>
    </div>
  );
};

export interface PasswordFormData {
  password: string;
  confirmPassword: string;
}

const PasswordEditor = ({
  setEditing,
}: {
  setEditing: (value: boolean) => void;
}): JSX.Element => {
  const {
    state: { transformed: state },
    error,
    call,
  } = useAsyncCall(
    useCallback((password: string) => {
      return apiService.user.updatePassword(password);
    }, [])
  );

  const { handleSubmit, formState, setFocus, register, getValues } =
    useForm<PasswordFormData>({});

  useEffect(() => {
    setFocus('password');
  }, [setFocus]);

  const onSubmit = handleSubmit((data: PasswordFormData) => {
    call(data.password);
    setEditing(false);
  });

  const validateConfirmPassword = (confirmPassword: string): boolean => {
    return confirmPassword === getValues('password');
  };

  return (
    <form onSubmit={onSubmit} className='w-75'>
      <div>
        <h2 className='font-bold text-base mb-2.5'>New Password</h2>
        <input
          type='password'
          autoComplete='off'
          {...register('password', {
            required: true,
            minLength: 8,
          })}
          className={`${
            formState.errors.password ? 'field-error' : 'field'
          } flex items-center my-0`}
        ></input>
      </div>

      <div className='mt-3'>
        <h2 className='font-bold text-base mb-2.5'>Confirm New Password</h2>
        <input
          type='password'
          autoComplete='off'
          {...register('confirmPassword', {
            validate: validateConfirmPassword,
          })}
          className={`${
            formState.errors.confirmPassword ? 'field-error' : 'field'
          } flex items-center my-0`}
        ></input>
      </div>

      {formState.errors.password ? (
        <div className='text-red-005 font-medium text-3xs'>
          Password must be at least 8 characters
        </div>
      ) : (
        formState.errors.confirmPassword && (
          <div className='text-red-005 font-medium text-3xs'>
            Passwords do not match
          </div>
        )
      )}

      <div className='w-full mt-4'>
        {error && (
          <div className='w-full text-center text-3xs text-red-005'>
            Saving Failed. Err Msg: {err2s(error)}
          </div>
        )}

        <div className='w-full flex justify-between mt-1'>
          <button
            type='button'
            onClick={() => setEditing(false)}
            className='btn-secondary w-33 h-10'
          >
            Cancel
          </button>

          <button
            type='submit'
            className='btn-primary w-33 h-10 flex justify-center items-center'
            disabled={
              state.isRunning ||
              !!formState.errors.password ||
              !!formState.errors.confirmPassword
            }
          >
            {state.isRunning && <Loading text='' containerClassName='mr-2' />}
            Save
          </button>
        </div>
      </div>
    </form>
  );
};
