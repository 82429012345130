import { Link } from '@remix-run/react';
import { format } from 'date-fns';
import capitalize from 'lodash/capitalize';
import { useState } from 'react';
import useSWR from 'swr';

import {
  type DtoInvoice,
  type DtoOrgSubscription,
  EnumsInvoiceDownloadLinkType,
  EnumsInvoiceType,
  EnumsOrgSubscriptionPlan,
} from '@lp-lib/api-service-client/public';

import { useLiveAsyncCall } from '../../hooks/useAsyncCall';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { apiService } from '../../services/api-service';
import { type Organization } from '../../types';
import { formatCurrency } from '../../utils/currency';
import { BillingIcon } from '../icons/BillingIcon';
import { CalendarIcon } from '../icons/CalendarIcon';
import { EmailIcon2 } from '../icons/EmailIcon';
import { NewWindowIcon } from '../icons/NewWindowIcon';
import { Loading } from '../Loading';
import { useMyOrganization } from '../Organization/hooks/organization';
import { PaymentUtils } from '../Payment/utils';
import { ProductPricingTable } from '../Product/SubscriptionChange';
import {
  ProductUtils,
  useNavigateToSubscriptionChange,
} from '../Product/utils';

function ChangePlanButton() {
  const navigate = useNavigateToSubscriptionChange();
  return (
    <button
      type='button'
      onClick={navigate}
      className='btn-secondary w-40 h-10'
    >
      Change Plan
    </button>
  );
}

function TrialAndCancel(props: { subscription: DtoOrgSubscription }) {
  const { subscription } = props;

  let message;
  if (subscription.trialEnd && subscription.cancelAt) {
    message = `Your trial will end on ${format(
      new Date(subscription.trialEnd),
      'MMM d, yyyy'
    )}, and your subscription will be canceled.`;
  } else if (subscription.trialEnd) {
    message = `You will be billed for your subscription starting on ${format(
      new Date(subscription.trialEnd),
      'MMM d, yyyy'
    )}
     after your trial ends`;
  } else if (subscription.cancelAt) {
    message = `Your subscription will be canceled on ${format(
      new Date(subscription.cancelAt),
      'MMM d, yyyy'
    )}`;
  }

  if (!message) {
    return null;
  }

  return (
    <div className='flex items-center gap-1 text-icon-gray text-sms'>
      <CalendarIcon className='w-3 h-3 fill-current' />
      <div>{message}</div>
    </div>
  );
}

function CurrentPlanSection(props: {
  organization: Organization;
  subscription: DtoOrgSubscription;
}) {
  const { organization, subscription } = props;

  return (
    <section className='w-full'>
      <div className='w-full flex items-center justify-between'>
        <p className='text-xl font-medium text-white'>Current Plan</p>
        <ChangePlanButton />
      </div>
      <div className='mt-5 w-full bg-modal rounded-xl py-6 pl-7.5 pr-4.5 flex justify-between items-center'>
        <div className='flex flex-col gap-2.5'>
          <div className='flex items-baseline gap-1'>
            <div className='font-bold text-lg text-tertiary'>
              {subscription.productName}
            </div>
            {organization.maxSize !== null && (
              <div className='text-sms font-bold text-icon-gray'>
                (Up to {organization.maxSize} users)
              </div>
            )}
          </div>
          {subscription.amount && subscription.billingInterval && (
            <div className='text-sms flex gap-1'>
              <div>
                <span className='text-white'>
                  {formatCurrency(
                    PaymentUtils.CalculateDiscountedPrice(
                      subscription.amount,
                      subscription.discount?.coupon
                    )
                  )}
                </span>
                <span className='text-icon-gray'>
                  /{ProductUtils.FormatInterval(subscription.billingInterval)}
                </span>
              </div>

              {subscription.discount && subscription.discount.coupon && (
                <p className='text-icon-gray'>
                  ({PaymentUtils.FormatCouponOff(subscription.discount.coupon)}{' '}
                  {subscription.discount.endAt ? (
                    <>
                      until{' '}
                      <strong>
                        {format(
                          new Date(subscription.discount.endAt),
                          'MMM d, yyyy'
                        )}
                      </strong>
                    </>
                  ) : (
                    'forever'
                  )}
                  , regular price {formatCurrency(subscription.amount)}/
                  {ProductUtils.FormatInterval(subscription.billingInterval)})
                </p>
              )}
            </div>
          )}
          <TrialAndCancel subscription={subscription} />
        </div>
        {!subscription.cancelAt && (
          <div>
            {subscription.billingInterval && (
              <p className='text-sms text-icon-gray'>
                Billed {capitalize(subscription.billingInterval)}
              </p>
            )}
            {subscription.currentPeriodEnd && (
              <p className='text-sms text-white'>
                Renews{' '}
                {format(new Date(subscription.currentPeriodEnd), 'MMM d, yyyy')}
              </p>
            )}
          </div>
        )}
      </div>
    </section>
  );
}

function PaymentMethodSection(props: {
  organization: Organization;
  subscription: DtoOrgSubscription;
}) {
  const { organization, subscription } = props;

  const { call: handleClick, state } = useLiveAsyncCall(async () => {
    const resp = await apiService.organization.createSubscriptionPortalSession(
      organization.id
    );
    window.open(resp.data.url, '_blank');
  });

  return (
    <section className='w-full'>
      <div className='w-full flex items-center justify-between'>
        <p className='text-xl font-medium text-white'>Payment Method</p>
        {subscription.customerId && (
          <button
            type='button'
            onClick={handleClick}
            disabled={state.state.isRunning}
            className='btn-secondary w-30 h-10'
          >
            Manage
          </button>
        )}
      </div>
      <div className='mt-5 w-full bg-modal rounded-xl py-6 px-7.5 flex gap-4'>
        {subscription.paymentMethod?.card && (
          <div className='w-3/12'>
            <div className='text-sms text-icon-gray'>Card information</div>
            <div className='text-sms text-white'>
              {capitalize(subscription.paymentMethod.card.brand)} ending in{' '}
              {subscription.paymentMethod.card.last4}
            </div>
          </div>
        )}
        {subscription.paymentMethod?.email && (
          <div className='w-2/12'>
            <div className='text-sms text-icon-gray'>Email</div>
            <div className='text-sms text-white'>
              {subscription.paymentMethod?.email}
            </div>
          </div>
        )}
        <div className='w-5/12'></div>
        <div className='w-2/12'></div>
      </div>
    </section>
  );
}

function ReceiptButton(props: { id: string }) {
  const [isFetchingReceipt, setIsFetchingReceipt] = useState(false);
  const handleOpenReceiptWebsite = useLiveCallback(async () => {
    setIsFetchingReceipt(true);
    try {
      const resp = await apiService.otp.getReceipt(props.id);
      if (resp.data.receiptUrl) {
        window.open(resp.data.receiptUrl, '_blank');
      }
    } finally {
      setIsFetchingReceipt(false);
    }
  });
  return (
    <button
      type='button'
      className='btn w-2/12 text-icon-gray flex gap-2 items-center font-medium'
      disabled={isFetchingReceipt}
      onClick={handleOpenReceiptWebsite}
    >
      <NewWindowIcon className='w-5 h-5 fill-current' />
      <p>View Details</p>
    </button>
  );
}

function HistorySection(props: { invoices: DtoInvoice[] }) {
  const { invoices } = props;

  return (
    <section className='w-full'>
      <p className='text-xl font-medium text-white'>History</p>
      <div className='w-full px-7.5 py-3 flex gap-4 text-sms text-icon-gray'>
        <p className='w-2/12'>Date</p>
        <p className='w-2/12'>Amount Due</p>
        <p className='w-3/12'>Description</p>
        <p className='w-3/12'>Type</p>
        <p className='w-2/12'></p>
      </div>
      <div className='w-full flex flex-col gap-1'>
        {invoices.map((invoice) => (
          <div
            key={invoice.id}
            className='w-full flex items-center gap-4 bg-modal text-white rounded-xl px-7.5 py-5 text-sms'
          >
            <div className='w-2/12'>
              {format(new Date(invoice.createdAt), 'MMM d, yyyy')}
            </div>
            <div className='w-2/12'>
              {formatCurrency(invoice.amountDue / 100, true)}
            </div>
            <div className='w-3/12'>{invoice.description}</div>
            <div className='w-3/12'>
              {invoice.type === EnumsInvoiceType.InvoiceTypeSubscription
                ? 'Subscription'
                : 'Single Event Purchase'}
            </div>
            {invoice.downloadLinkType ===
            EnumsInvoiceDownloadLinkType.InvoiceDownloadLinkTypePdf ? (
              <a
                href={invoice.receiptUrl || invoice.hostedInvoiceUrl || ''}
                target='_blank'
                className='btn w-2/12 text-icon-gray flex gap-2 items-center font-medium'
                rel='noreferrer'
              >
                <NewWindowIcon className='w-5 h-5 fill-current' />
                <p>View Details</p>
              </a>
            ) : (
              <ReceiptButton id={invoice.id} />
            )}
          </div>
        ))}
      </div>
    </section>
  );
}

function BillingSettingsSubscriber(props: { organization: Organization }) {
  const { organization } = props;

  const { data } = useSWR([organization.id, '/billing'], async () => {
    const subscription = (
      await apiService.organization.getSubscriptionDetails(organization.id)
    ).data;
    const invoices = (await apiService.stripe.queryInvoices()).data.invoices;

    return {
      subscription,
      invoices,
    };
  });

  if (!organization || !data)
    return <Loading text='' containerClassName='w-full h-full' />;
  const { subscription, invoices } = data;

  return (
    <div className='w-full text-white'>
      <header className='flex justify-between items-center'>
        <div className='flex items-center gap-2.5 text-white'>
          <BillingIcon className='h-6 w-6 fill-current' />
          <p className='text-2xl font-medium'>Billing</p>
        </div>
        <Link
          to='mailto:support@lunapark.com'
          className='text-icon-gray flex items-center gap-2'
        >
          <EmailIcon2 className='fill-current w-5 h-5' />
          <p className=' text-sm font-medium'>Contact Support</p>
        </Link>
      </header>
      <main className='mt-13 w-full flex flex-col gap-10'>
        <CurrentPlanSection
          organization={organization}
          subscription={subscription}
        />
        {!!subscription.subscriptionId && (
          <PaymentMethodSection
            organization={organization}
            subscription={subscription}
          />
        )}
        {invoices.length > 0 && <HistorySection invoices={invoices} />}
      </main>
    </div>
  );
}

function BillingSettingsNonSubscriber(props: { organization: Organization }) {
  const { data } = useSWR([props.organization.id, '/billing'], async () => {
    const subscription = (
      await apiService.organization.getSubscriptionDetails(
        props.organization.id
      )
    ).data;

    const products = (await apiService.product.getPublicProducts()).data
      .marketed;
    return {
      subscription,
      products,
    };
  });
  if (!data) return <Loading text='' containerClassName='w-full h-full' />;

  return (
    <div className='w-full text-white'>
      <ProductPricingTable
        marketedProducts={data.products}
        subscription={data.subscription}
        organization={props.organization}
        hideLegacyCard
      />
    </div>
  );
}

export function BillingSettings() {
  const organization = useMyOrganization();

  if (!organization) return null;

  switch (organization.subscription.plan) {
    case EnumsOrgSubscriptionPlan.OrgSubscriptionPlanFree:
      return <BillingSettingsNonSubscriber organization={organization} />;
    default:
      return <BillingSettingsSubscriber organization={organization} />;
  }
}
