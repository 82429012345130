import copy from 'copy-to-clipboard';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useAsyncCall } from '../../hooks/useAsyncCall';
import { err2s } from '../../utils/common';
import { CopyLinkIcon } from '../icons/CopyLinkIcon';
import { CopyTooltipIcon } from '../icons/CopyTooltipIcon';
import { Loading } from '../Loading';

interface ItemContainerReadonlyProps {
  title: string;
  prefix?: string;
  value: string;
  copyable?: boolean;
}

interface ItemContainerWritableProps extends ItemContainerReadonlyProps {
  editable: true;
  editLabel?: string;
  hint?: React.ReactNode;
  minLength?: number;
  maxLength?: number;
  formClassName?: string;
  validate?: (value: string) => string | Promise<string>;
  onSave: (value: string) => Promise<void>;
}

type ItemContainerProps =
  | (ItemContainerReadonlyProps & { editable: false })
  | (ItemContainerWritableProps & { editable: true });

export const ItemContainer = (props: ItemContainerProps): JSX.Element => {
  const [editing, setEditing] = useState(false);

  return (
    <div className='text-white'>
      <h2 className='font-bold text-base mb-2.5'>{props.title}</h2>

      {props.editable && editing ? (
        <Editor {...props} setEditing={setEditing} />
      ) : (
        <View {...props} setEditing={setEditing} />
      )}
    </div>
  );
};

const View = (
  props: ItemContainerProps & {
    setEditing: (value: boolean) => void;
  }
): JSX.Element => {
  const [showCopyTooltip, setShowCopyTooltip] = useState<boolean>(false);
  const timerIdRef = useRef<ReturnType<typeof setTimeout>>();

  const handleCopy = () => {
    copy(`${props.prefix}${props.value}`, {
      format: 'text/plain',
    });
    setShowCopyTooltip(true);
    if (timerIdRef.current) {
      clearTimeout(timerIdRef.current);
    }
    timerIdRef.current = setTimeout(() => {
      setShowCopyTooltip(false);
    }, 3000);
  };

  return (
    <div className='w-full text-sms flex flex-row gap-5'>
      <div className='min-w-70 flex items-center gap-5'>
        <div>
          {props.prefix && (
            <span className='text-icon-gray'>{props.prefix}</span>
          )}
          {props.value}
        </div>
        {props.copyable && (
          <div className='relative flex items-center'>
            <button
              type='button'
              onClick={handleCopy}
              className='btn text-icon-gray hover:text-white transition-colors'
            >
              <CopyLinkIcon className='w-4 h-4' />
            </button>

            <CopyTooltipIcon
              className={`absolute left-1/2 transform -translate-x-1/2 top-5 transition-opacity ${
                showCopyTooltip ? 'opacity-100' : 'opacity-0'
              }`}
              text='Link copied'
            />
          </div>
        )}
      </div>

      {props.editable && (
        <div>
          <button
            type='button'
            onClick={() => props.setEditing(true)}
            className='btn text-primary'
          >
            {props.editLabel || 'Edit'}
          </button>
        </div>
      )}
    </div>
  );
};

export interface FormData {
  value: string;
}

const Editor = (
  props: ItemContainerWritableProps & {
    setEditing: (value: boolean) => void;
  }
): JSX.Element | null => {
  const { value, prefix, onSave, setEditing, minLength, maxLength, validate } =
    props;

  const {
    state: { transformed: state },
    error,
    call,
  } = useAsyncCall(onSave);

  const formReturned = useForm<FormData>({
    mode: 'onSubmit',
    defaultValues: {
      value,
    },
  });
  const { handleSubmit, formState, setFocus, register } = formReturned;

  useEffect(() => {
    setFocus('value');
  }, [setFocus]);

  const onSubmit = handleSubmit(async (data: FormData) => {
    await call(data.value);
  });

  useEffect(() => {
    if (state.isDone && !error) {
      setEditing(false);
    }
  }, [error, setEditing, state.isDone]);

  const alert = `Must be ${minLength} to ${maxLength} characters`;

  return (
    <form
      onSubmit={onSubmit}
      className={`w-75 flex flex-col gap-2 ${props.formClassName}`}
    >
      <div
        className={`${
          formState.errors.value ? 'field-error' : 'field'
        } w-full flex items-center my-0`}
      >
        {prefix && (
          <div className='flex justify-center items-center text-icon-gray'>
            {prefix}
          </div>
        )}

        <input
          autoComplete='off'
          {...register('value', {
            required: true,
            minLength,
            maxLength,
            validate,
          })}
          placeholder={alert}
          className='flex-1 bg-transparent outline-none'
        ></input>
      </div>

      {(formState.errors.value || error) && (
        <div className='text-red-005 font-medium text-3xs'>
          {formState.errors.value ? alert : err2s(error)}
        </div>
      )}

      {props.hint}

      <div className='w-full flex justify-between'>
        <button
          type='button'
          onClick={() => setEditing(false)}
          className='btn-secondary w-33 h-10'
        >
          Cancel
        </button>

        <button
          type='submit'
          className='btn-primary w-33 h-10 flex justify-center items-center'
          disabled={state.isRunning}
        >
          {state.isRunning && <Loading text='' containerClassName='mr-2' />}
          Save
        </button>
      </div>
    </form>
  );
};
