import { type ReactNode } from 'react';

import { type User } from '../../types/user';
import { OrgProvider } from '../Organization/Details';
import { useUpdateMyOrganization } from '../Organization/hooks/organization';

export function OrganizationPanel(props: {
  user: User;
  children: ReactNode;
}): JSX.Element | null {
  const { user } = props;
  const updateMyOrg = useUpdateMyOrganization();

  if (!user.organizer?.organization) return null;
  return (
    <OrgProvider
      initOrg={user.organizer.organization}
      onOrgUpdated={updateMyOrg}
    >
      <div className='px-2'>{props.children}</div>
    </OrgProvider>
  );
}
